import React from 'react'
import { StaticPage } from '@obeta/layouts/lib/staticPage/staticPage'

const StaticContentPage: React.FC = () => {
  return <StaticPage />
}

export default StaticContentPage

export async function getStaticProps() {
  return {
    props: {},
  }
}
